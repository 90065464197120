<template>
  <div style="max-width: 500px" class="mt-15 pt-15 px-3 ma-auto redemption-redeem">
    <h2 class="pb-5 text-center text-capitalize secondary--text">
      {{ `${$t('label.brandName')} ${$t('label.verifyRedemption')} ` }}
    </h2>
    <div class="py-10 secondary rounded white--text">
      <div class="text-center pb-5">
        <img src="/assets/image/logo.png" alt="logo" width="200px" />
        <div style="font-size: 12px" class="text-uppercase">
          {{ $t('label.slogan') }}
        </div>
      </div>
      <div class="infoborder">
        <div class="px-8">
          <v-row no-gutters class="pb-5 align-center text-capitalize">
            <v-col cols="5" class="font-weight-bold text-left">
              <div>Merchant</div>
            </v-col>
            <v-col cols="7" class="font-weight-bold text-right">
              <div>{{ this.$route.query.branch }}</div>
            </v-col>
          </v-row>
          <v-row no-gutters class="pb-5 align-center text-capitalize">
            <v-col cols="5" class="font-weight-bold text-left">
              <div>Point</div>
            </v-col>
            <v-col cols="7" class="font-weight-bold text-right">
              <div>{{ this.$route.query.total_redeem_point }}</div>
            </v-col>
          </v-row>
          <v-row v-if="transactionNo" no-gutters class="pb-5 align-center text-capitalize">
            <v-col cols="5" class="font-weight-bold text-left">
              <div>Transaction No.</div>
            </v-col>
            <v-col cols="7" class="font-weight-bold text-right">
              <div>{{ transactionNo }}</div>
            </v-col>
          </v-row>
        </div>
      </div>
      <div v-if="!redemptionDetailObj.countdownTime && !transactionNo">
        <div
          v-if="redemptionDetailObj.status !== 2"
          class="px-10 text--white font-weight-bold text-center"
        >
          <div class="ma-auto">
            <div class="pt-5 pb-3 text-uppercase">
              {{ $t('label.branchName') }}
            </div>
            <div v-if="this.$route.query.branch">
              <input
                v-model="branchName"
                :placeholder="this.$route.query.branch"
                class="inputPin branchName"
                disabled
                type="text"
              />
              <div v-if="erroMsg" class="red--text">
                {{ erroMsg }}
              </div>
            </div>
            <div v-else>
              <input
                v-model="branchName"
                class="inputPin branchName"
                type="text"
              />
              <div>This field is required.</div>
            </div>
          </div>
          <div class="pt-5 pb-3 text-uppercase">{{ $t('label.enterPin') }}</div>
          <div class="ma-auto">
            <CodeInput
              :loading="false"
              type="number"
              class="inputPin ma-auto"
              v-on:complete="onKeyPinComplete"
              :fields="4"
              :autoFocus="true"
              ref="code"
            />
          </div>
          <div class="red--text">
            {{ erroMsg2 }}
          </div>
          <div class="pt-5">
            *Merchant key in PIN code to verify redemption redemption
          </div>
        </div>
        <div v-else class="px-8 text--white font-weight-bold text-center">
          <div class="pt-5 text-center">
            <div
              v-if="redemptionDetailObj.redemptionUseType == RedemptionUseType.MERCHANT"
              class="d-flex justify-space-between align-center pb-5 yellow--text"
            >
              <div>Received Payment</div>
              <div>{{ redemptionDetailObj.receivedPayment }}</div>
            </div>
            <div>*Redemption redemption completed</div>
            <div>{{ redemptionDetailObj.redemptionAt }}</div>
          </div>
        </div>
      </div>
      <div
        v-else-if="!transactionNo"
        class="text-center pt-7"
        style="font-size: 20px; font-weight: bold"
      >
        Try again in {{ countDown }} seconds
      </div>
    </div>
    <br />
  </div>
</template>

<script>
  import common from '@/core/mixin/common.mixin';
  import CodeInput from 'vue-verification-code-input';
  // import { Redemption_Use_Type } from '@/core/constants/enums';
  import { i18nHelper } from '@/core/utils';
  import {
    USER_REDEMPTION_GET_USER_REDEMPTION,
    USER_REDEMPTION_INITIAL_GET_USER_REDEMPTION_STATE,
    USER_REDEMPTION_INITIAL_UPDATE_CHECK_USER_REDEMPTION_STATE,
    USER_REDEMPTION_UPDATE_CHECK_USER_REDEMPTION
  } from '@/core/store/user-redemption.module';

  export default {
    name: 'VerifyRedeem',
    components: { CodeInput },
    mixins: [common],
    data: () => ({
      erroMsg: null,
      erroMsg2: null,
      branchName: null,
      countDown: null,
      redemptionDetailInfo: [],
      redemptionDetailObj: [],
      transactionNo: null
    }),
    mounted() {
      this.initialGetRedemption();
    },
    computed: {
      getUserRedemptionComplete() {
        return this.$store.state.userRedemption.userRedemption.complete;
      },
      updateCheckUserRedemptionComplete() {
        return this.$store.state.userRedemption.checkUserRedemption.complete;
      }
    },
    watch: {
      getUserRedemptionComplete() {
        let response = this.$store.state.userRedemption.userRedemption;
        this.inportUserRedemption(response);
      },
      updateCheckUserRedemptionComplete() {
        let response = this.$store.state.userRedemption.checkUserRedemption;
        this.inportCheckUserRedemption(response);
      }
    },
    methods: {
      onKeyPinComplete(value) {
        if (this.$route.query.branch == '') {
          if (this.branchName == null || this.branchName == '') {
            this.erroMsg = 'This field is required.';
            this.$refs.code.values = ['', '', '', ''];
          } else {
            this.erroMsg = '';
          }
        } else {
          if (this.$route.query.branch) {
            this.branchName = this.$route.query.branch;
          } else {
            this.$route.query.branch = this.branchName;
          }
        }

        if (this.$refs.code.values.includes('')) {
          this.erroMsg2 = 'This field is required.';
        } else {
          this.erroMsg2 = '';
        }

        if (
          this.branchName != null &&
          this.branchName != '' &&
          !this.$refs.code.values.includes('')
        ) {
          this.updateCheckUserRedemption(value);
        }
      },
      countDownTimer() {
        // Get today's date and time
        var now = new Date().getTime();
        // Set the date we're counting down to
        var countDownDate = new Date(
          this.redemptionDetailObj.countdownTime
        ).getTime();
        setTimeout(() => {
          // Find the distance between now and the count down date
          var distance = countDownDate - now;
          // Time calculations for days, hours, minutes and seconds
          // var days = Math.floor(distance / (1000 * 60 * 60 * 24));
          // var hours = Math.floor(
          //   (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
          // );
          // var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
          var seconds = Math.floor((distance % (1000 * 60)) / 1000);

          // Output the result in an element with id="demo"

          // If the count down is over, write some text
          if (distance < 0) {
            this.initialGetRedemption();
          } else {
            // Output the result in an element with id="demo"
            this.countDown = seconds;
            this.countDownTimer();
          }
        }, 1000);
      },
      inportUserRedemption(response) {
        this.redemptionDetailObj = response.data;
        if (this.redemptionDetailObj.countdownTime) {
          this.countDownTimer();
        }
        console.log(this.redemptionDetailObj);
        this.initialGetRedemptionDetailState();
      },
      inportCheckUserRedemption(response) {
        let type;
        if (response.code == 0) {
          type = 'success';
          this.transactionNo = response.data.transaction_no;
        } else {
          type = 'error';
        }
        let title = response.message;
        let description = '';
        let buttons = [
          {
            color: 'secondary',
            text: i18nHelper.getMessage('label.ok'),
            action: () => {
              this.closeAppDialogInfo();
              this.initialGetRedemption();
              if (response.code == 0) {
                // location.reload();
              }
            }
          }
        ];

        this.$refs.code.values = ['', '', '', ''];
        this.initialUpdateCheckUserRedemptionState();
        this.openAppDialogInfo(type, title, description, buttons);
      },
      initialGetRedemption() {
        this.getRedemptionDetail();
      },
      getRedemptionDetail() {
        let data = {
          userId: this.$route.query.user_id,
          productId: this.$route.query.product_id,
          // campaignId: this.$route.query.campaign_id,
          branchName: this.$route.query.branch,
          branchId: this.$route.query.branch_id,
          merchantId: this.$route.query.merchant_id,
          totalPoint: this.$route.query.total_redeem_point
        };
        this.$store.dispatch(USER_REDEMPTION_GET_USER_REDEMPTION, { data });
      },
      updateCheckUserRedemption(value) {
        let data = {
          redemptionCode: this.$route.query.redemption_code,
          pinVerification: value.toUpperCase(),
          productId: this.$route.query.product_id,
          branchName: this.$route.query.branch,
          branchId: this.$route.query.branch_id,
          merchantId: this.$route.query.merchant_id,
          // campaignId: this.$route.query.campaign_id,
          userId: this.$route.query.user_id,
          totalPoint: this.$route.query.total_redeem_point

        };

        if (value.length == 4) {
          this.$store.dispatch(USER_REDEMPTION_UPDATE_CHECK_USER_REDEMPTION, {
            data
          });
        }
      },
      initialGetRedemptionDetailState() {
        this.$store.dispatch(USER_REDEMPTION_INITIAL_GET_USER_REDEMPTION_STATE);
      },
      initialUpdateCheckUserRedemptionState() {
        this.$store.dispatch(
          USER_REDEMPTION_INITIAL_UPDATE_CHECK_USER_REDEMPTION_STATE
        );
      }
    }
  };
</script>
<style lang="scss">
  .redemption-redeem {
    .rounded {
      border-radius: 20px !important;
    }

    .inputPin {
      width: 275px !important;

      .react-code-input {
        display: flex;
        justify-content: space-between;

        input {
          font-size: 25px;
          color: #000;
          background: #fff;
          border-radius: 10px;
          text-transform: uppercase;
        }
      }
    }

    .branchName {
      font-size: 15px;
      color: #000;
      padding: 10px;
      background: #fff;
      border-radius: 10px;
      text-align: center;
    }

    .infoborder {
      width: 100%;
      margin: auto;
      border-bottom: 2px dashed #fff;
    }
  }
</style>
